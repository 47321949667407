<template>
  <div>
    <ApplyHereList title="Apply Here" collection="apply-here" :items= applyHereList />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApplyHereList from "@/components/admin/ApplyHereList";

export default {
  name: "ApplyHere",

  components: {
    ApplyHereList,
  },

  computed: {
    ...mapGetters(["applyHereList"]),
  },

  methods: {
    ...mapActions(["getApplyHereList"]),
  },

  mounted() {
    this.getApplyHereList();
  },
};
</script>
